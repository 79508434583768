import { json } from "react-router-dom";

import { authActions } from "../store/auth/auth.store";

const rootLoader = async () => {
  const domainConfig = await authActions.getDomainConfig();

  if (domainConfig) {
    authActions.init(domainConfig);
  }

  return json(domainConfig);
};

export default rootLoader;

import classNames from "classnames/bind";
import { useCallback, useMemo, useState } from "react";

import { ReactComponent as AssembleIcon } from "../../assets/assemble_icon.svg";
import { ReactComponent as ConnectIcon } from "../../assets/connect_icon.svg";
import { ReactComponent as OptimizeIcon } from "../../assets/optimize_icon.svg";
import { useAuthStore } from "../../store/auth/auth.store";
import LaunchButton from "../LaunchButton/LaunchButton";
import RequestAccessForm from "../RequestAccessForm/RequestAccessForm";
import NoAccessModal from "../NoAccessModal/NoAccessModal";

import styles from "./MainContent.module.scss";

const cn = classNames.bind(styles);

const MainContent = () => {
  const [showRequestAccessForm, setShowRequestAccessForm] = useState(false);

  const [
    userName,
    hasAssembleAccess,
    hasConnectAccess,
    hasOptimiseAccess,
    issuer,
  ] = useAuthStore((state) => [
    state.userName,
    state.hasAssembleAccess,
    state.hasConnectAccess,
    state.hasOptimiseAccess,
    state.issuer,
  ]);

  const onClose = useCallback(() => {
    setShowRequestAccessForm(false);
  }, [setShowRequestAccessForm]);

  const onOpenRquestModal = useCallback(() => {
    setShowRequestAccessForm(true);
  }, [setShowRequestAccessForm]);

  const hasNoAccess = useMemo(() =>
    !hasAssembleAccess
    && !hasConnectAccess
    && !hasOptimiseAccess,
  [hasAssembleAccess, hasConnectAccess, hasOptimiseAccess]);

  return (
    <div className={cn("main-content")}>
      <h1>Hello {userName}</h1>
      <div className={cn("main-content__subtitle")}>Create, deliver and optimise your content.</div>
      <div className={cn("main-content__info")}>
        <h2>Your apps</h2>
        <p>Below you’ll find all the applications which you have active in your current subscription.</p>
      </div>
      <div className={cn("apps", { disabled: hasNoAccess })}>
        <div className={cn("app", !hasAssembleAccess && "disabled")}>
          <div className={cn("app__header")}>
            <div className={cn("app__icon")}>
              <AssembleIcon />
            </div>
            <div className={cn("app__title")}>
              <h3>Assemble</h3>
              <span>Application by Intelligent Content</span>
            </div>
          </div>
          <div className={cn("app__desc")}>
            Compile channel-ready assets tailored to individual audience and user needs through predictive adaptation,
            enabling dynamic content experiences with ease.
          </div>
          <LaunchButton onOpenRequestModal={onOpenRquestModal} hasAccess={hasAssembleAccess} link={`https://www.hoxton.co?iss=${issuer}`} />
        </div>
        <div className={cn("app", !hasConnectAccess && "disabled")}>
          <div className={cn("app__header")}>
            <div className={cn("app__icon")}>
              <ConnectIcon />
            </div>
            <div className={cn("app__title")}>
              <h3>Connect</h3>
              <span>Application by Intelligent Content</span>
            </div>
          </div>
          <div className={cn("app__desc")}>
            Align your assembled assets with relevant data and insights, delivering personalized communications to the
            right audience, at the right place and time.
          </div>
          <LaunchButton onOpenRequestModal={onOpenRquestModal} hasAccess={hasConnectAccess} link={`https://www.hoxton.co?iss=${issuer}`} />
        </div>
        <div className={cn("app", !hasOptimiseAccess && "disabled")}>
          <div className={cn("app__header")}>
            <div className={cn("app__icon")}>
              <OptimizeIcon />
            </div>
            <div className={cn("app__title")}>
              <h3>Optimize</h3>
              <span>Application by Intelligent Content</span>
            </div>
          </div>
          <div className={cn("app__desc")}>
            Leverage live performance data and insights to adapt and optimize asset performance in real-time, augmenting
            user experiences for maximum impact.
          </div>
          <LaunchButton onOpenRequestModal={onOpenRquestModal} hasAccess={hasOptimiseAccess} link={`https://analytics.hogarth.ai?iss=${issuer}`} />
        </div>
      </div>

      {/* No access modal */}
      {hasNoAccess && <NoAccessModal onOpenRquestModal={onOpenRquestModal} />}

      {/* Reqeust access form */}
      {showRequestAccessForm && <RequestAccessForm onClose={onClose} />}
    </div>
  );
};

export default MainContent;

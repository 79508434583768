import classNames from "classnames/bind";

import styles from "./NoAccessModal.module.scss";

const cn = classNames.bind(styles);

const NoAccessModal = ({ onOpenRquestModal }: {
  onOpenRquestModal: () => void;
}) => (
  <div className={cn("no-access-modal-overlay")}>
    <div className={cn("no-access-modal-content")}>
      <div className={cn("no-access-modal-header")}>
        <h3>Activate your apps</h3>
      </div>

      <div className={cn("no-access-modal-body")}>
        Currently, there are no active applications associated with your account.
        Kindly reach out to a system administrator or your account manager for
        further assistance with managing your account.
      </div>

      <div className={cn("no-access-modal-footer")}>
        <button onClick={onOpenRquestModal} className={cn("button", "button--primary")}>Manage your account</button>
      </div>
    </div>
  </div>
);

export default NoAccessModal;

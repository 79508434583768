import { CognitoUser } from "amazon-cognito-identity-js";

import { PERMISSION_GROUPS } from "./auth.constants";

export function getUserNameFromCognito(user: CognitoUser) {
  const session = user.getSignInUserSession();
  const idToken = session?.getIdToken();

  // sso user
  if (idToken?.payload?.given_name && idToken?.payload?.family_name) {
    return `${idToken.payload.given_name} ${idToken.payload.family_name}`;
  }

  // user/pass login
  if (idToken?.payload["cognito:username"]) {
    return idToken.payload["cognito:username"] as string;
  }

  if (user?.getUsername()) {
    return user?.getUsername();
  }

  return "";
}

export function getGivenNameFromCognito(user: CognitoUser): string | null {
  const session = user.getSignInUserSession();
  const idToken = session?.getIdToken();

  // sso user
  return idToken?.payload?.given_name ?? null;
}

export function getGroupsFromCognito(user: CognitoUser): string[] | null {
  const session = user.getSignInUserSession();
  const idToken = session?.getIdToken();

  // sso user
  return idToken?.payload["cognito:groups"] ?? null;
}

export function hasAccessToApp(user: CognitoUser, group: PERMISSION_GROUPS): boolean {
  return !!getGroupsFromCognito(user)?.includes(group);
}

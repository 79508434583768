import classNames from "classnames/bind";
import { ChangeEvent, useCallback } from "react";

import { ACCESS, ACCESS_TYPE } from "../../utils/requestFormUtil";

import styles from "./CheckboxGroup.module.scss";

const cn = classNames.bind(styles);

const CheckboxGroup = ({ handleOnToggle, checkboxMap, title }: {
  handleOnToggle: (shouldCheck: boolean, access: string) => void;
  title: string;
  checkboxMap: Record<ACCESS, string> | Record<ACCESS_TYPE, string>;
}) => {
  const handleToggle = useCallback((value: string) =>
    (e: ChangeEvent<HTMLInputElement>) => handleOnToggle(e.target.checked, value), [handleOnToggle]);

  return (
    <div className={cn("form-control-checkboxes")}>
      <p>{title}</p>

      {Object.entries(checkboxMap).map(([key, value]) => (
        <span key={key} className={cn("form-control-checkbox")}>
          <input onChange={handleToggle(value)} type="checkbox" name={key} id={key} />
          <label htmlFor={key}>{value}</label>
        </span>
      ))}
    </div>
  );
};

export default CheckboxGroup;

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MainContent from "../../components/MainContent/MainContent";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "../../styles/general.module.scss";

function Dashboard() {
  return (
    <div className="App">
      <Header />

      <div className={styles.content}>
        <MainContent />
        <Sidebar />
      </div>

      <Footer />
    </div>
  );
}

export default Dashboard;

import { Amplify, Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { DConfOutput } from "dconf-service/src/types";

import { getGivenNameFromCognito, getUserNameFromCognito, getGroupsFromCognito, hasAccessToApp } from "./utils";
import { PERMISSION_GROUPS, PROVIDER_ACCESS_DENIED } from "./auth.constants";

interface AuthState {
  userId: string | null;
  userName: string | null;
  givenName: string | null;
  cognitoGroups: string[] | null;
  hasAssembleAccess: boolean;
  hasConnectAccess: boolean;
  hasOptimiseAccess: boolean;
  isAuthenticated: boolean | null;
  isLoading: boolean;
  errorMessage: string | null;
  domainConfig: DConfOutput | null;
  issuer: string | null;
  init: (config: DConfOutput) => void;
  getDomainConfig: () => Promise<DConfOutput>;
  checkSession: () => Promise<boolean>;
  federatedSignIn: (customProvider: string) => Promise<unknown>;
  signOut: () => Promise<void>;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    immer((set) => {
      const updateUser = (user: CognitoUser | null) => {
        set((state) => {
          if (!user) {
            state.isAuthenticated = false;
            state.userId = null;
            state.userName = null;
            state.givenName = null;
            state.cognitoGroups = null;
            state.hasAssembleAccess = false;
            state.hasConnectAccess = false;
            state.hasOptimiseAccess = false;

            return;
          }

          state.isAuthenticated = true;
          state.userId = user.getUsername();
          state.userName = getUserNameFromCognito(user);
          state.givenName = getGivenNameFromCognito(user);
          state.cognitoGroups = getGroupsFromCognito(user);
          state.hasAssembleAccess = hasAccessToApp(user, PERMISSION_GROUPS.ASSEMBLE);
          state.hasConnectAccess = hasAccessToApp(user, PERMISSION_GROUPS.CONNECT);
          state.hasOptimiseAccess = hasAccessToApp(user, PERMISSION_GROUPS.OPTIMISE);
        });

        return;
      };

      const setLoading = (loading: boolean) =>
        set((state) => {
          state.isLoading = loading;
        });

      const setErrorMessage = (message: string | null) =>
        set((state) => {
          state.errorMessage = message;
        });

      const setSuccess = () => {
        setLoading(false);
        setErrorMessage(null);
      };

      const setFailure = (message: string | null) => {
        setLoading(false);
        setErrorMessage(message);
      };

      const setDomainConfig = (config: DConfOutput) =>
        set((state) => {
          if (!state.domainConfig) {
            state.domainConfig = config;
            state.issuer = config.auth.federatedSignIn?.[0].issuer || null;
          }
        });

      return {
        userId: null,
        userName: null,
        givenName: null,
        cognitoGroups: null,
        hasAssembleAccess: false,
        hasConnectAccess: false,
        hasOptimiseAccess: false,
        isAuthenticated: null,
        isLoading: false,
        errorMessage: null,
        domainConfig: null,
        issuer: null,

        init: (config: DConfOutput) => {
          Amplify.configure(config.auth.cognito);
          Hub.listen("auth", async ({ payload: { event, data } }) => {
            switch (event) {
              case "signIn":
              case "cognitoHostedUI":
                updateUser(await Auth.currentAuthenticatedUser());
                setSuccess();

                break;
              case "signOut":
                updateUser(null);
                setSuccess();
                break;
              case "signIn_failure":
              case "cognitoHostedUI_failure":
                if (data.message === PROVIDER_ACCESS_DENIED) {
                  console.error("You need the tile to be assigned!");
                }

                setFailure(data.message);
                break;
              case "autoSignIn":
                updateUser(await Auth.currentAuthenticatedUser());
                setSuccess();
                break;
              case "tokenRefresh":
                updateUser(await Auth.currentAuthenticatedUser());
                setSuccess();
                break;
              case "tokenRefresh_failure":
                setFailure(data.message);
                updateUser(null);
                break;
            }
          });
        },

        getDomainConfig: async (): Promise<DConfOutput> => {
          const response = await fetch(`/oauth-config?domain=${window.location.hostname}&source=lp`);

          if (response.ok) {
            const config = await response.json();

            setDomainConfig(config.data);

            return config.data;
          } else {
            const text = await response.text();

            return Promise.reject(new Error(text));
          }
        },

        checkSession: () => {
          setLoading(true);

          return Auth.currentSession()
            .then(async () => {
              updateUser(await Auth.currentAuthenticatedUser());
              setLoading(false);

              return true;
            })
            .catch(() => {
              updateUser(null);
              setLoading(false);

              return false;
            });
        },

        federatedSignIn: (customProvider = "ICDev") => {
          setLoading(true);

          return Auth.federatedSignIn({ customProvider }).catch((error) => {
            console.error("federate login error", error);
            setLoading(false);

            return Promise.reject(error);
          });
        },

        signOut: async () => {
          return Auth.signOut();
        },
      };
    })
  )
);

const { init, checkSession, federatedSignIn, getDomainConfig, signOut } = useAuthStore.getState();

export const authActions = {
  init,
  checkSession,
  federatedSignIn,
  getDomainConfig,
  signOut,
};

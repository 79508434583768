import type { RouteObject } from "react-router-dom";
import { createBrowserRouter, Outlet } from "react-router-dom";

import Dashboard from "../pages/Dashboard/Dashboard";
import ErrorPage from "../pages/ErrorPage";
import LoginPage from "../pages/Auth/LoginPage";
import AccessDeniedPage from "../pages/Auth/AccessDeniedPage";

import ProtectedRoute from "./ProtectedRoute";
import rootLoader from "./rootLoader";

const routes: RouteObject[] = [
  {
    path: "/",
    loader: rootLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
        ],
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "access-denied",
        element: <AccessDeniedPage />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;

import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const { message: errorMessage = "" } = useRouteError() as Error;

  return (
    <div>
      <h3>Something went wrong</h3>
      {errorMessage && <h4>{errorMessage}</h4>}
    </div>
  );
};

export default ErrorPage;

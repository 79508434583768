export type ACCESS = "assemble" | "connect" | "optimise";
export type ACCESS_TYPE = "approval" | "create" | "view";

export const ACCESS_MAP: Record<ACCESS, string> = {
  assemble: "Assemble",
  connect: "Connect",
  optimise: "Optimise",
};

export const ACCESS_TYPE_MAP: Record<ACCESS_TYPE, string> = {
  approval: "Approval",
  create: "Create & Edit",
  view: "View only",
};

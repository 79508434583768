import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames/bind";

import { authActions, useAuthStore } from "../../../store/auth/auth.store";
import { ACCESS_DENIED, PROVIDER_ACCESS_DENIED } from "../../../store/auth/auth.constants";
import { useAuthEvents } from "../../../store/auth/useAuthEvents";
import { ReactComponent as Logo } from "../../../assets/ic_logo.svg";

import styles from "./LoginPage.module.scss";

const cn = classNames.bind(styles);

const LoginPage = () => {
  const { federatedSignIn } = authActions;
  const [isAuthLoading, domainConfig, isAuthenticated, errorMessage] = useAuthStore((state) => [
    state.isLoading,
    state.domainConfig,
    state.isAuthenticated,
    state.errorMessage,
  ]);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const issuer = searchParams.get("iss");

  const isRedirect = searchParams.has("code");
  const isErrorRedirect = searchParams.has("error");

  const isLoading = isAuthLoading || isRedirect;

  const federatedSignInConfigs = useMemo(() => domainConfig?.auth.federatedSignIn ?? [], [domainConfig]);

  const handleProvider = useCallback((provider: string) => () => federatedSignIn(provider), [federatedSignIn]);

  useEffect(() => {
    if (issuer) {
      const customConf = federatedSignInConfigs.find((config) => issuer === config.issuer);

      if (customConf) {
        // TODO: make the Error handling for federatedSignIn
        federatedSignIn(customConf.customProvider).catch((error) => console.error("federate login error", error));
      }
    } else if (isAuthenticated === null) {
      authActions.checkSession().catch((error) => console.error(error));
    } else if (isAuthenticated === true) {
      const origin = location.state?.from?.pathname || "/";

      navigate(origin);
    }
  }, [federatedSignIn, isAuthenticated, issuer, location, navigate, federatedSignInConfigs]);

  /**
   * Could handle all error cases
   * */
  useEffect(() => {
    if (isErrorRedirect) {
      const error = searchParams.get("error");

      switch (error) {
        case ACCESS_DENIED: {
          navigate("/access-denied");
          break;
        }

        default: {
          console.error(error);
        }
      }
    }
  }, [searchParams, isErrorRedirect, navigate]);

  /**
   * Handles situation when we don't get an events from Auth as it's too late
   * */
  useEffect(() => {
    if (errorMessage === PROVIDER_ACCESS_DENIED || errorMessage?.includes(ACCESS_DENIED)) {
      navigate("/access-denied");
    }
  }, [navigate, errorMessage]);

  /**
   * Handles situation when user has access to wpp but don't have access to the app
   * */
  useAuthEvents((event, data) => {
    const message = data?.message;

    if (
      ["signIn_failure", "cognitoHostedUI_failure", "customState_failure"].includes(event)
      && (message === PROVIDER_ACCESS_DENIED || message.includes(ACCESS_DENIED))
    ) {
      navigate("/access-denied");
    }
  });

  return (
    <div className={cn("loading-container")}>
      {isLoading
        ? <Logo className={cn("logo")} />
        : federatedSignInConfigs.map(({ name, customProvider }) => <button key={name} className={cn("button", "button--primary")} onClick={handleProvider(customProvider)}>Sign in with {name}</button>)}
    </div>
  );
};

export default LoginPage;

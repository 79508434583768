import classNames from "classnames/bind";

import styles from "../../../components/MainContent/MainContent.module.scss";

const cn = classNames.bind(styles);

const Message = () => (
  <>
    You haven&apos;t been assigned access in Okta yet.
    <br />
    We are rolling out access to everyone this summer.
  </>
);

const AccessDenied = () => {
  return (
    <div>
      <h3>Access restricted</h3>
      <h4>
        <Message />
      </h4>
      <a className={cn("button", "button--primary")} href="/">Back to main page</a>
    </div>
  );
};

export default AccessDenied;

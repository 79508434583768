import classNames from "classnames/bind";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";

import { ACCESS_MAP, ACCESS_TYPE_MAP } from "../../utils/requestFormUtil";

import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";

import styles from "./RequestAccessForm.module.scss";

const cn = classNames.bind(styles);

const RequestAccessForm = ({ onClose }: {
  onClose: () => void;
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState(new Set<string>());
  const [accessType, setAccessType] = useState(new Set<string>());
  const [isLoading, setIsLoading] = useState(false);

  const handleOverlayClick = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      onClose();
    }
  }, [onClose]);

  const stopPropagation = useCallback((event: React.MouseEvent | React.KeyboardEvent) => event.stopPropagation(), []);

  const isFormInvalid = useMemo(() =>
    !name
    || !email
    || !access.size
    || !accessType.size,
  [name, email, access, accessType]);

  const toggleAccess = useCallback((shouldCheck: boolean, accessName: string) => {
    if (shouldCheck) {
      access.add(accessName);
    } else {
      access.delete(accessName);
    }

    setAccess(new Set(access));
  }, [access]);

  const toggleAccessType = useCallback((shouldCheck: boolean, accessTypeName: string) => {
    if (shouldCheck) {
      accessType.add(accessTypeName);
    } else {
      accessType.delete(accessTypeName);
    }

    setAccessType(new Set(accessType));
  }, [accessType]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setName(e.target.value), [setName]);
  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), [setEmail]);

  const handleSubmit = useCallback((e: FormEvent) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      fetch("/request-access", {
        method: "POST",
        body: JSON.stringify({
          name,
          email,
          access: [...access.values()],
          accessType: [...accessType.values()],
        }),
      }).then(onClose).catch(onClose);
    } catch {
      onClose();
    }
  }, [name, email, access, accessType, onClose]);

  return (
    <div className={cn("request-form-overlay")} onClick={onClose} onKeyDown={handleOverlayClick}>
      <div className={cn("request-form-content")} onClick={stopPropagation} onKeyDown={stopPropagation}>
        <div className={cn("request-form-header")}>
          <h3>Manage your account</h3>
          <button onClick={onClose}>×</button>
        </div>

        <p>Just fill in the details below to request app access for your account.</p>
        <p>Our team will check it out and get back to you soon!</p>

        <form onSubmit={handleSubmit}>
          <div className={cn("request-form-body")}>
            <div className={cn("form-control")}>
              <label htmlFor="name">Name</label>
              <input required onChange={handleNameChange} type="text" id="name" name="name" placeholder="Enter your full name" />
            </div>

            <div className={cn("form-control")}>
              <label htmlFor="email">Email address</label>
              <input required onChange={handleEmailChange} type="email" id="email" name="email" placeholder="Enter your email address" />
            </div>

            <CheckboxGroup title="Access request" checkboxMap={ACCESS_MAP} handleOnToggle={toggleAccess} />
            <CheckboxGroup title="Request type" checkboxMap={ACCESS_TYPE_MAP} handleOnToggle={toggleAccessType} />
          </div>

          <div className={cn("request-form-footer")}>
            <button disabled={isFormInvalid || isLoading} type="submit" className={cn("button", "button--primary", { disabled: isLoading || isFormInvalid })}>{isLoading ? "Loading..." : "Submit request"}</button>
            <button className={cn("button", "button--secondary")} onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestAccessForm;

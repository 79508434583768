import classNames from "classnames/bind";

import styles from "../MainContent/MainContent.module.scss";

const cn = classNames.bind(styles);

type LaunchButtonProps = {
  hasAccess: boolean;
  link: string;
  onOpenRequestModal: () => void;
};

const LaunchButton = ({ hasAccess, link, onOpenRequestModal }: LaunchButtonProps) => {
  if (!hasAccess) {
    return <button className={cn("button", "button--secondary", "disabled")} onClick={onOpenRequestModal}>Request</button>;
  }

  return <a className={cn("button", "button--primary")} href={link}>Launch App</a>;
};

export default LaunchButton;

import classNames from "classnames/bind";

import { ReactComponent as Logo } from "../../../assets/ic_logo.svg";

import styles from "../LoginPage/LoginPage.module.scss";

const cn = classNames.bind(styles);

const LoadingPage = () => {
  return (
    <div className={cn("loading-container")}>
      <Logo className={cn("logo")} />
    </div>
  );
};

export default LoadingPage;
